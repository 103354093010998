






import Component, { mixins } from 'vue-class-component';
import ButtonLike from '@/mixins/ButtonLike';
import Icon from '@/components/ui/icon/Icon.vue';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'Button',
  components: { Icon },
})
export default class Button extends mixins(ButtonLike) {
  baseClass = 'button';
  classList: Array<string> = ['button--hoverable'];
}
