import { Component, Prop, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Colorizable from './Colorizable';
import Sizeable from './Sizeable';
import ClassListComposer from './ClassListComposer';

export type ButtonType = 'link' | 'plain' | 'outline';
export type InheritedProps = { [id: string]: string | number | boolean };
export const INHERITED_PROPERTIES: Array<string> = ['type', 'rounded', 'disabled', 'color', 'size'];

@Component
export default class ButtonLike extends mixins(Colorizable, Sizeable, ClassListComposer) {
  declare $parent: Vue & ButtonLike;

  baseClass = 'button-like';
  classGetters: Array<Function> = [];

  @Prop({ default: 'plain' }) type: ButtonType;
  @Prop({ default: false }) rounded: boolean;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: 'primary' }) declare color: string;

  public buttonTypeClassName(): string {
    if (this.$parent.type) return `${this.baseClass}--${this.$parent.type}`;
    return `${this.baseClass}--${this.type}`;
  }

  public roundedClassName(): string | null {
    if (this.rounded || this.$parent.rounded) return `${this.baseClass}--rounded`;
    return null;
  }

  public disabledClassName(): string | null {
    if (this.disabled || this.$parent.disabled) return `${this.baseClass}--disabled`;
    return null;
  }

  created() {
    this.classGetters.push(this.buttonTypeClassName);
    this.classGetters.push(this.roundedClassName);
    this.classGetters.push(this.disabledClassName);
  }
}
