import { Component, Vue, Prop } from 'vue-property-decorator';

export type Size = 'tiny' | 'small' | 'medium' | 'big' | 'huge';
export const DEFAULT_SIZE: Size = 'medium';

@Component
export default class Sizeable extends Vue {
  baseClass = 'sizeable';
  classGetters: Array<Function> = [];
  $parent: Vue & Sizeable;

  @Prop({ default: DEFAULT_SIZE }) size: Size;

  created() {
    this.classGetters.push(this.sizeClassName);
  }

  public sizeClassName(): string | null {
    if (this.size && DEFAULT_SIZE !== this.size) return `${this.baseClass}--size-${this.size}`;
    if (this.$parent.size) return `${this.baseClass}--size-${this.$parent.size}`;
    return null;
  }
}
